import type { RouteLocationNormalizedLoaded } from 'vue-router/auto'
import { omit } from 'remeda'

export function getListOfNodes<T>(connection: { edges: { node: T }[] } | { nodes: T[] } | undefined): T[] {
  if (!connection)
    return []
  if ('nodes' in connection)
    return connection.nodes
  return connection.edges.map(edge => edge.node)
}

export function getPaginationParams(route: RouteLocationNormalizedLoaded, defaultParams: object) {
  const { first, last } = route.query
  if (first === undefined && last === undefined) {
    return defaultParams
  }
  else {
    return {
      ...route.query,
      first: first ? Number.parseInt(first.toString(), 10) : undefined,
      last: last ? Number.parseInt(last.toString(), 10) : undefined,
    }
  }
}

export function clearPaginationParams(
  route: RouteLocationNormalizedLoaded,
  defaultParams: Record<string, any> = {},
) {
  const PAGINATION_PARAMS = ['first', 'last', 'before', 'after']
  const queryWithoutPaginationParams = omit(route.query, PAGINATION_PARAMS)

  return { ...queryWithoutPaginationParams, ...defaultParams }
}

export const SECTIONS_LIMIT = 50
export const WIDGETS_LIMIT = 50
